var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Dialog',{attrs:{"dialog":_vm.dialog,"dialog-width":900},on:{"close":function($event){return _vm.$emit('close', true)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Update Product ")]},proxy:true},{key:"body",fn:function(){return [_c('v-form',{ref:"inventoryForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.updateOrCreate.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"formValid"}},[_c('v-row',[(false)?_c('v-col',{staticClass:"py-0",attrs:{"md":"9"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"hide-details":"","mandatory":"","row":""},on:{"change":function($event){return _vm.getSerialNumber()}},model:{value:(_vm.new_product.product_type),callback:function ($$v) {_vm.$set(_vm.new_product, "product_type", $$v)},expression:"new_product.product_type"}},[_c('v-radio',{attrs:{"color":"blue darken-4","label":"Piping","value":"piping"}}),_c('v-radio',{attrs:{"color":"blue darken-4","label":"Other items","value":"other items"}})],1)],1):_vm._e(),(_vm.new_product.product_status == 3)?_c('v-col',{attrs:{"md":"12"}},[_c('label',{staticClass:"btx-label fw-500 mt-2 required",attrs:{"for":"product-name"}},[_vm._v(" Name ")]),_c('TextInput',{class:{
								required: !_vm.new_product.name,
							},attrs:{"hide-top-margin":"","hide-details":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"id":"product-name","placeholder":"Name","rules":[_vm.vrules.required(_vm.new_product.name, 'Name')]},model:{value:(_vm.new_product.name),callback:function ($$v) {_vm.$set(_vm.new_product, "name", $$v)},expression:"new_product.name"}})],1):_vm._e(),_c('v-col',{attrs:{"md":"12"}},[_c('label',{staticClass:"btx-label fw-500 mt-2 required",attrs:{"for":"product-name"}},[_vm._v(" Qty ")]),_c('TextInput',{class:{
								required: !_vm.new_product.quantity,
							},attrs:{"hide-top-margin":"","hide-details":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"type":"number","id":"qty","placeholder":"Qty","rules":[_vm.vrules.required(_vm.new_product.quantity, 'Quantity')]},model:{value:(_vm.new_product.quantity),callback:function ($$v) {_vm.$set(_vm.new_product, "quantity", $$v)},expression:"new_product.quantity"}})],1),(_vm.new_product.product_status == 3)?[_c('v-col',{attrs:{"md":"12"}},[_c('label',{staticClass:"btx-label fw-500 mt-2",attrs:{"for":"product-name"}},[_vm._v(" Description ")]),_c('TextAreaInput',{attrs:{"hide-details":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"id":"description","placeholder":"Description"},model:{value:(_vm.new_product.description),callback:function ($$v) {_vm.$set(_vm.new_product, "description", $$v)},expression:"new_product.description"}})],1),_c('v-col',{attrs:{"md":"12"}},[_c('label',{staticClass:"btx-label fw-500 mt-2",attrs:{"for":"group"}},[_vm._v(" Category ")]),_c('AutoCompleteInput',{attrs:{"hide-top-margin":"","hide-details":"","items":_vm.groupList,"disabled":_vm.pageLoading,"loading":_vm.pageLoading,"id":"group","placeholder":"Category"},model:{value:(_vm.new_product.group),callback:function ($$v) {_vm.$set(_vm.new_product, "group", $$v)},expression:"new_product.group"}})],1),_c('v-col',{attrs:{"md":"12"}},[_c('label',{staticClass:"btx-label fw-500 mt-2 required",attrs:{"for":"rate-price"}},[_vm._v(" Selling Price/Unit Price ")]),_c('PriceInput',{class:{
								required: !_vm.new_product.rate,
							},attrs:{"hide-top-margin":"","hide-details":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"id":"rate-price","rules":[_vm.vrules.required(_vm.new_product.rate, 'Selling Price/Unit Price')],"placeholder":"Selling Price/Unit Price"},model:{value:(_vm.new_product.rate),callback:function ($$v) {_vm.$set(_vm.new_product, "rate", $$v)},expression:"new_product.rate"}})],1),_c('v-col',{attrs:{"md":"12"}},[_c('label',{staticClass:"btx-label fw-500 mt-2 required",attrs:{"for":"purchasing_price"}},[_vm._v(" Purchasing Price")]),_c('PriceInput',{class:{
								required: !_vm.new_product.purchasing_price,
							},attrs:{"hide-top-margin":"","hide-details":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"id":"purchasing_price","rules":[_vm.vrules.required(_vm.new_product.purchasing_price, 'Purchasing Price')],"placeholder":"Purchasing Price"},model:{value:(_vm.new_product.purchasing_price),callback:function ($$v) {_vm.$set(_vm.new_product, "purchasing_price", $$v)},expression:"new_product.purchasing_price"}})],1)]:_vm._e()],2)],1)]},proxy:true},{key:"action",fn:function(){return [_c('v-btn',{attrs:{"disabled":_vm.pageLoading,"depressed":"","tile":""},on:{"click":function($event){return _vm.$emit('close', true)}}},[_vm._v(" Close")]),_c('v-btn',{staticClass:"white--text",attrs:{"disabled":_vm.pageLoading,"loading":_vm.pageLoading,"depressed":"","color":"blue darken-4","tile":""},on:{"click":function($event){return _vm.updateOrCreate()}}},[_vm._v(" Save ")])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }