<template>
	<Dialog :dialog="rejectDialog" :dialog-width="600">
		<template v-slot:title> Reject {{ type }}</template>
		<template v-slot:body>
			<v-form
				ref="rejectForm"
				v-model.trim="formValid"
				lazy-validation
				v-on:submit.stop.prevent="deleteRow"
			>
				<v-row class="delete-dialog">
					<v-col md="12" class="py-0 red--text"> </v-col>
					<v-col md="12" class="py-0">
						<TextAreaInput
							:disabled="pageLoading"
							:loading="pageLoading"
							readonly
							id="remark"
							hide-details
							v-model="rejctremark"
						></TextAreaInput>
					</v-col>
				</v-row>
			</v-form>
		</template>
		<template v-slot:action>
			<v-btn depressed tile :disabled="pageLoading" v-on:click="$emit('close', true)"> Close </v-btn>
			<slot name="extra-btn"></slot>
			<!-- <v-btn
				class="white--text"
				:loading="pageLoading"
				:disabled="pageLoading"
				depressed
				color="red lighten-1"
				tile
				v-on:click="deleteRow()"
			>
				Yes! Reject
			</v-btn> -->
		</template>
	</Dialog>
</template>
<script>
import Dialog from "@/view/components/Dialog";
import TextAreaInput from "@/view/components/TextAreaInput";
import ApiService from "@/core/services/api.service";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";
import { mapGetters } from "vuex";

export default {
	name: "delete-template",
	title: "Delete Template",
	props: {
		type: {
			type: String,
			default: null,
		},
		status: {
			type: String,
			default: "mark_as_rejected",
		},
		uuid: {
			type: String,
			default: null,
		},
		rejctremark: {
			type: String,
			default: null,
		},
		rejectType: {
			type: String,
			default: null,
		},
		rejectDialog: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			pageLoading: false,
			formValid: true,
			remark: null,
		};
	},
	components: {
		Dialog,
		TextAreaInput,
	},
	methods: {
		deleteRow() {
			const _this = this;
			const formErrors = _this.validateForm(_this.$refs.rejectForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
			}

			if (!_this.$refs.rejectForm.validate()) {
				return false;
			}

			/* if (!_this.remark) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: "Remark is required" }]);
				return false;
			} */

			_this.pageLoading = true;
			const payload = {
				action: _this.status,
				selected: [_this.uuid],
				remark: _this.remark ? _this.remark : null,
			};
			ApiService.patch(`${_this.rejectType}/bulk-action/status`, payload)
				.then(() => {
					_this.$emit("success");
					_this.$emit("close", true);
					_this.$store.dispatch(SET_LOCAL_DB);
					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: `Success ! ${_this.type} has been Rejected.` },
					]);
				})
				.catch((error) => {
					_this.logError(error);
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
	},
	computed: {
		...mapGetters(["errors"]),
	},
};
</script>
