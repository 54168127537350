<template>
	<div>
		<Dialog :dialog="dialog" v-on:close="$emit('close', true)" :dialog-width="900">
			<template v-slot:title> Update Product </template>
			<template v-slot:body>
				<v-form
					ref="inventoryForm"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="updateOrCreate"
				>
					<v-row>
						<v-col md="9" class="py-0" v-if="false">
							<v-radio-group
								v-on:change="getSerialNumber()"
								class="ma-0"
								hide-details
								mandatory
								row
								v-model="new_product.product_type"
							>
								<v-radio color="blue darken-4" label="Piping" value="piping"></v-radio>
								<v-radio color="blue darken-4" label="Other items" value="other items"></v-radio>
							</v-radio-group>
						</v-col>

						<v-col md="12" v-if = "new_product.product_status == 3">
							<label for="product-name" class="btx-label fw-500 mt-2 required"> Name <!-- <pre>{{productData}}</pre> --> </label>
							<TextInput
								hide-top-margin
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								id="product-name"
								placeholder="Name"
								v-model="new_product.name"
								:rules="[vrules.required(new_product.name, 'Name')]"
								:class="{
									required: !new_product.name,
								}"
							>
							</TextInput>
						</v-col>
						<v-col md="12">
							<label for="product-name" class="btx-label fw-500 mt-2 required"> Qty </label>
							<TextInput
								hide-top-margin
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								type="number"
								id="qty"
								placeholder="Qty"
								v-model="new_product.quantity"
								:rules="[vrules.required(new_product.quantity, 'Quantity')]"
								:class="{
									required: !new_product.quantity,
								}"
							>
							</TextInput>
						</v-col>
						<template v-if = "new_product.product_status == 3">
						<v-col md="12" >
							<label for="product-name" class="btx-label fw-500 mt-2"> Description </label>

							<TextAreaInput
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								id="description"
								placeholder="Description"
								v-model="new_product.description"
							>
							</TextAreaInput>
						</v-col>
						<v-col md="12" >
							<label for="group" class="btx-label fw-500 mt-2"> Category </label>
							<AutoCompleteInput
								hide-top-margin
								hide-details
								:items="groupList"
								:disabled="pageLoading"
								:loading="pageLoading"
								id="group"
								placeholder="Category"
								v-model="new_product.group"
								
							></AutoCompleteInput>
						</v-col>
						<v-col md="12">
							<label for="rate-price" class="btx-label fw-500 mt-2 required"> Selling Price/Unit Price </label>
							<PriceInput
								hide-top-margin
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								id="rate-price"
								:rules="[vrules.required(new_product.rate, 'Selling Price/Unit Price')]"
								:class="{
									required: !new_product.rate,
								}"
								placeholder="Selling Price/Unit Price"
								v-model="new_product.rate"
							></PriceInput>
						</v-col>
						<v-col md="12">
							<label for="purchasing_price" class="btx-label fw-500 mt-2 required"> Purchasing Price</label>
							<PriceInput
								hide-top-margin
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								id="purchasing_price"
								:rules="[vrules.required(new_product.purchasing_price, 'Purchasing Price')]"
								:class="{
									required: !new_product.purchasing_price,
								}"
								placeholder="Purchasing Price"
								v-model="new_product.purchasing_price"
							></PriceInput>
						</v-col>

						</template>
					</v-row>
				</v-form>
			</template>
			<template v-slot:action>
				<v-btn :disabled="pageLoading" depressed tile @click="$emit('close', true)"> Close</v-btn>
				<v-btn
					:disabled="pageLoading"
					:loading="pageLoading"
					class="white--text"
					depressed
					color="blue darken-4"
					tile
					@click="updateOrCreate()"
				>
					Save
				</v-btn>
			</template>
		</Dialog>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
//import { SET_LINEITEMS } from "@/core/services/store/lineitem.module";
import TextAreaInput from "@/view/components/TextAreaInput";
import { UpdatePendingInventory } from "@/core/lib/inventory.lib";
import { ValidateSerialNumber,  } from "@/core/lib/common.lib";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";
import TextInput from "@/view/components/TextInput";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import Dialog from "@/view/components/Dialog";
import PriceInput from "@/view/components/PriceInput";


export default {
	name: "product-create-shortcut",
	props: {
		dialog: {
			type: Boolean,
			default: false,
		},
		/* supplierId: {
			type: Number,
			default: 0,
		}, */
		/* indexId: {
			type: Number,
			default: 0,
		}, */
		/* requestId: {
			type: Number,
			default: 0,
		}, */
		productData: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			pageLoading: false,
			pendingstatus: true,
			formValid: true,
			manageCategoryDialog: false,
			serialNumberError: null,
			groupList: [],
			new_product: {
                id: null,
				product_type: null,
				name: null,
				description: null,
				quantity: null,
				uuid: null,
				discount_value: null,
				discount_type: null,
				frontend_uuid: null,
				product_status: null,
				order: null,
				is_sheet_uploaded: null,
				parent: null,
				product: null,
				product_barcode: null,
				rate: null,
				purchasing_price: null,
				sub_total: null,
				total: null,
				uom: null,
				group: null
		
				

			},
		};
	},
	// watch: {
	// 	productData(param) {
	//         console.log(param,"param");

	// 		//this.prices = cloneDeep(param);
	// 		//this.priceList = cloneDeep(param);
	// 	},

	// },
	components: {
		TextInput,
		TextAreaInput,
		AutoCompleteInput,
		Dialog,
		PriceInput,
	},

	methods: {
		getCategories(param) {
			this.groupList = param;
		},
		async updateOrCreate() {
			const _this = this;
			const formErrors = _this.validateForm(_this.$refs.inventoryForm);
			//console.log(formErrors);

			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}
			if (!_this.$refs.inventoryForm.validate()) {
				return false;
			}

			/* if (!this.supplierId || this.supplierId == null) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: "Supplier is required" }]);
				return false;
			} */

			const formData = {
                description: this.new_product.description,
                discount: this.new_product.discount,
                discount_value: this.new_product.discount_value,
                discount_type: this.new_product.discount_type,
                frontend_uuid: this.new_product.uuid,
                id: this.new_product.id,
                is_sheet_uploaded: this.new_product.is_sheet_uploaded,
                order: this.new_product.order,
                parent: this.new_product.parent,
                product_status: this.new_product.product_status,


                product: this.new_product.product,
                product_barcode: this.new_product.product_barcode,
                product_type: this.new_product.product_type,
                quantity: this.new_product.quantity,


                rate: this.new_product.rate,
				purchasing_price: this.new_product.purchasing_price,
                sub_total: this.new_product.sub_total,

                total: this.new_product.total,
                type: this.new_product.type,
                uom: this.new_product.uom,
				name: this.new_product.name,
				uuid: this.new_product.uuid,
				group: this.new_product.group
				
			};
          //  console.log(formData)
			try {

               if (_this.productData && _this.productData.uuid) {
					 await UpdatePendingInventory(_this.productData.uuid, formData);

					/* _this.$router.replace({
						name: "product-detail",
						params: { uuid },
						query: { t: new Date().getTime() },
					});
 */
					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Product has been updated." },
                        _this.$emit("close", true),
                         _this.$emit("success"),
					]);
					_this.$store.dispatch(SET_LOCAL_DB);
					//_this.clearLocalData();
				}




				/* _this.pageLoading = true;
				const data = await UpdateInventory(_this.productData.uuid, formData);

				_this.$store.commit(SET_MESSAGE, [
					{ model: true, message: "Success ! Product has been Created." },
				]);
				_this.$store.dispatch(SET_LOCAL_DB);
				_this.$store.dispatch(SET_LINEITEMS).then(() => {
					_this.$emit("close", true);
					_this.$emit("success", true);
				}); */
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
		validateSerialNumber(serial_number, type) {
			const _this = this;
			const params = {
				type: type,
				serial_number: serial_number,
			};
			return new Promise((resolve, reject) => {
				_this.pageLoading = true;
				_this.serialNumberError = null;
				ValidateSerialNumber(`validate-serialnumber`, params)
					.then((output) => {
						if (output.exists) {
							_this.serialNumberError = `${serial_number} already exists`;
							resolve(true);
						} else {
							resolve(false);
						}
					})
					.catch((error) => {
						reject(error);
					})
					.finally(() => {
						_this.pageLoading = false;
					});
			});
		},
		/* async getSerialNumber() {
			const _this = this;
			_this.serialNumberError = null;
			let type = _this.new_product.product_type == "piping" ? "piping" : "other-items";
			const { barcode } = await GenrateSerialNumber(type);
			_this.new_product.serial_number = barcode;
		}, */
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
	},
	mounted() {    
		(this.new_product = {
			
			name: this.productData?.product_name,
			// group:  this.productData?.product_name,
			description: this.productData?.description,
			discount: this.productData?.discount,
			discount_value: this.productData?.discount_value,
			discount_type: this.productData?.discount_type,
			frontend_uuid: this.productData?.uuid,
			id: this.productData?.id,
			is_sheet_uploaded: this.productData?.is_sheet_uploaded,
			order: this.productData?.order,
			parent: this.productData?.parent,
			product_status: this.productData?.product_status,
			product: this.productData?.product,

			product_barcode: this.productData?.product_barcode,
            product_type: this.productData?.product_type,
            quantity: this.productData?.quantity,

			rate: this.productData?.rate,
			sub_total: this.productData?.sub_total,
			total: this.productData?.total,

			type: this.productData?.type,
			uom: this.productData?.uom,
			group: this.productData?.group


			
			
		}),
		(this.groupList = this.localDB("groups", []));
		//this.getSerialNumber();
	},
};
</script>
