<template>
	<v-sheet class="inventory" style="height: calc(100vh - 88px)">
		<template v-if="contentLoaded">
			<v-layout class="align-center light-blue-bg px-4 py-2">
				<v-flex md12>
					<v-layout class="justify-content-between align-items-center flex-wrap">
						<v-flex md5 class="d-flex align-items-center">
							<p class="my-auto font-level-3-bold  no-wrap" style="font-size: 15px">Purchase Order</p>
							<ShowBarcode
								:object="purchase_order"
								object-key="barcode"
								tooltip
								tooltip-text="purchase order #"
								click-to-copy
								label="barcode"
							></ShowBarcode>
							<div
								v-if="
									purchase_order && purchase_order.request_po_data && purchase_order.request_po_data.barcode
								"
								class="m-2"
							>
								<v-chip label color="green" outlined>
									<span class="fw-500" v-on:click="RequestPoDetail(purchase_order.request_po_data.uuid)">{{
										purchase_order.request_po_data.barcode
									}}</span>
								</v-chip>
							</div>
							<v-chip
								v-if="purchase_order.status_value"
								label
								:color="`${getStatusColor(purchase_order.status_value)} white--text text-capitilize`"
								class=""
							>
								{{ purchase_order.status_value }}
							</v-chip>
							<v-icon
								color="blue darken-1"
								v-if="purchase_order.status == 5"
								v-on:click="getRemarkDialog"
								left
								>mdi-information
							</v-icon>
							<v-chip
								v-if="purchase_order.receive_status_value"
								label
								:color="`${getStatusColor(
									purchase_order.receive_status_value
								)} white--text text--darken-4 text-capitilize`"
								class="mr-2"
							>
								{{ purchase_order.receive_status_value }}
							</v-chip>
							<v-chip
								v-if="purchase_order.force_close_status_value"
								label
								:color="`${getStatusColor(
									purchase_order.force_close_status_value
								)} white--text text--darken-4 text-capitilize`"
								class="mr-2"
							>
								{{ purchase_order.force_close_status_value }}
							</v-chip>
						</v-flex>
						<v-flex md7 class="text-right">
							<template>
								<div class="mr-1 d-inline-flex align-items-center">
									<span class="fw-500" style="font-size: 15px">Total :</span>
									<span class="green--text ml-1 fw-500" style="font-size: 15px">
										<ShowPrice :object="purchase_order" object-key="total" label="total"></ShowPrice>
									</span>
								</div>
								<v-btn
									v-if="false && purchase_order.is_price_changed"
									color="blue darken-4 text-white"
									class="ml-2"
									depressed
									:disabled="updatePriceLoading"
									:loading="updatePriceLoading"
									tile
									@click="updateChangePrice()"
								>
									Update Price
								</v-btn>

								<template v-if="lower_case(purchase_order.status_value) == 'approved' && checkPermission()">
									<v-btn
										v-if="!isRecieve"
										color="blue darken-4 white--text mr-1"
										tile
										depressed
										@click="fullyRecieved()"
									>
										Fully Receive
									</v-btn>
									<template v-if="isRecieve">
										<v-btn
											color="red lighten-1 white--text"
											tile
											class="ml-1"
											depressed
											@click="forceClose()"
										>
											Force Close
										</v-btn>
										<v-btn color="grey lighten-3 ml-1" tile depressed @click="cancleRecieveItem()"
											>Cancel</v-btn
										>
										<v-btn
											color="blue darken-4 white--text"
											class="ml-1"
											tile
											depressed
											@click="partiallyRecieved()"
											>Save</v-btn
										>
									</template>
									<template v-else>
										<v-btn color="blue darken-4 white--text" tile depressed @click="recieveItem()"
											>Partially Receive</v-btn
										>
									</template>
								</template>
								<template v-if="checkPermission()">
									<v-btn
										v-if="lower_case(purchase_order.status_value) == 'approved'"
										color="red text-white"
										class="ml-1"
										depressed
										tile
										@click="downloadPDF()"
										style="min-width: 30px"
									>
										<v-icon small>mdi-file-pdf</v-icon>
									</v-btn>

									<v-btn
										v-if="
											ApproveHidebutton && getPermission('purchase-order::approve') 
											&& purchase_order.status != 2
											&& purchase_order.status != 5
										"
										color="green darken-4 text-white"
										class="ml-2"
										depressed
										tile
										v-on:click="doAction('approve', purchase_order.id)"
									>
										Approve
									</v-btn>
									<v-btn
										v-if="
										ApproveHidebutton &&
										purchase_order.status != 2 
										&& purchase_order.status != 5
										&& getPermission('purchase-order::approve')"
										color="red darken-4 text-white"
										class="ml-2"
										depressed
										tile
										v-on:click="doAction('reject')"
									>
										Reject
									</v-btn>

									<v-btn
										v-if="
											ApproveHidebutton && lower_case(purchase_order.status_value) == 'draft'
										"
										color="blue darken-4 text-white"
										class="ml-2"
										depressed
										tile
										v-on:click="doAction('edit')"
									>
										<v-icon small left>mdi-pencil</v-icon>Edit
									</v-btn>

									<v-menu
										v-if="false && purchase_order.status != 2"
										bottom
										left
										origin="center center"
										transition="slide-y-transition"
										rounded="0"
										offset-y
										max-height="400px"
										:close-on-content-click="false"
										content-class="white-background"
									>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												color="blue darken-4 text-white"
												v-on="on"
												v-bind="attrs"
												class="ml-2"
												depressed
												tile
												><v-icon small left>mdi-more mdi-flip-h</v-icon>More</v-btn
											>
										</template>
										<v-list class="py-0">
											<v-list-item v-on:click="doAction('send-for-approval')">
												<v-list-item-title>
													<v-icon left color="blue darken-4">mdi-email-send-outline</v-icon>
													Send For Approval</v-list-item-title
												>
											</v-list-item>
											<v-divider class="m-0 p-0"></v-divider>
											<!-- <v-list-item v-on:click="doAction('approve')">
												<v-list-item-title>
													<v-icon left color="green">mdi-check-all</v-icon>
													Approve</v-list-item-title
												>
											</v-list-item> -->
											<!-- <v-divider class="m-0 p-0"></v-divider> -->
											<!-- <v-list-item v-on:click="doAction('reject')">
												<v-list-item-title>
													<v-icon left color="red lighten-1">mdi-cancel</v-icon>
													<span class="red--text text--lighten-1"> Reject </span>
												</v-list-item-title>
											</v-list-item> -->
											<v-list-item
												v-if="false && getPermission('inventory:delete')"
												v-on:click="doAction('delete')"
											>
												<v-list-item-title>
													<v-icon small left color="blue darken-4">mdi-delete</v-icon>
													Delete
												</v-list-item-title>
											</v-list-item>
											<!-- Used In responsive -->
											<template v-if="false && deviceWidth < 1360.98">
												<template v-if="!inventory.retired && inventory.initial_stock > 0">
													<v-list-item v-if="getPermission('checkout:create')" v-on:click="doAction('checkout')">
														<v-list-item-title>
															<v-icon small left color="blue darken-4">mdi-share-outline</v-icon>
															Issue
														</v-list-item-title>
													</v-list-item>
													<v-list-item
														v-if="getPermission('inventory:update')"
														v-on:click="doAction('retire-stock')"
													>
														<v-list-item-title>
															<v-icon small left color="blue darken-4">mdi-cancel</v-icon>
															Retire Stock
														</v-list-item-title>
													</v-list-item>
													<v-list-item v-if="getPermission('inventory:update')" v-on:click="doAction('retire')">
														<v-list-item-title>
															<v-icon small left color="blue darken-4">mdi-cancel</v-icon>
															Retire
														</v-list-item-title>
													</v-list-item>
												</template>
											</template>
										</v-list>
									</v-menu>
								</template>
								<v-btn
									v-on:click="backToListing('purchase-order')"
									color="grey lighten-3"
									class="ml-2"
									depressed
									tile
									><v-icon small left>mdi-arrow-left</v-icon>Back</v-btn
								>
								<v-btn
									v-if="false && inventory.retired == 0 && getPermission('inventory:update')"
									v-on:click="doAction('add-stock')"
									color="blue darken-4 text-white"
									class="mx-2"
									depressed
									tile
									><v-icon small left>mdi-playlist-plus</v-icon>Add Stock</v-btn
								>
							</template>
						</v-flex>
					</v-layout>
					<div class="d-flex flex-wrap">
						<div class="w-10 pr-3" v-if="false">
							<ImageTemplate circle :src="purchase_order.image"></ImageTemplate>
						</div>
						<div class="width-50">
							<div class="d-flex flex-wrap">
								<div class="width-50">
									<div class="mt-2 d-flex">
										<span class="fw-600 no-wrap">Supplier : </span>
										<span class="fw-500 text-truncate d-block">
											<ShowValue
												:object="purchase_order.supplier"
												object-key="display_name"
												label="Supplier"
											></ShowValue>
										</span>
									</div>
								</div>
								<div class="width-50">
									<div class="mt-2">
										<span class="fw-600">
											Ref Number
											<TooltipQuestion>
												<template v-slot:text>Reference Number Printed on pdf</template>
											</TooltipQuestion>
											:
										</span>
										<ShowValue
											:object="purchase_order"
											object-key="reference_number"
											label="ref number"
										></ShowValue>
									</div>
								</div>
								<div class="width-50">
									<div class="mt-2 d-flex">
										<span class="fw-600 no-wrap"> Project : </span>
										<span class="ml-1 fw-500 text-truncate d-block">
											<ShowValue
												:object="purchase_order.project"
												object-key="name"
												label="project"
											></ShowValue>
										</span>
									</div>
								</div>
								<div class="width-50">
									<div class="mt-2">
										<span class="fw-600"> Delivery Date : </span>
										<span class="fw-500 no-wrap">
											{{ formatDate(purchase_order.date) }}
										</span>
									</div>
								</div>
							</div>
						</div>
						<div class="width-50 d-flex flex-wrap">
							<div class="width-50">
								<div class="mt-2">
									<span class="fw-600 mr-2">Created Date: </span>
									<span class="fw-500 no-wrap">
										{{ formatDateTime(purchase_order.added_at) }}
									</span>
								</div>
							</div>
							<div class="width-50">
								<div class="mt-2">
									<span class="fw-600">Created by : </span>
									<span class="fw-500 no-wrap">
										<ShowValue
											:object="purchase_order"
											object-key="created_by"
											label="Created by"
										></ShowValue>
									</span>
								</div>
							</div>
							<div class="width-50">
								<div class="mt-2">
									<span class="fw-600">Updated Date : </span>
									<span class="fw-500 no-wrap">
										<template v-if="purchase_order.updated_at">
											{{ formatDateTime(purchase_order.updated_at) }}
										</template>
										<em v-else class="text-muted">no updated</em>
									</span>
								</div>
							</div>
							<div class="width-50">
								<div class="mt-2">
									<span class="fw-600">Updated by : </span>
									<span class="fw-500 no-wrap">
										<ShowValue :object="purchase_order" object-key="updated_by" label="updated by">
										</ShowValue>
									</span>
								</div>
							</div>
						</div>
						<div class="w-20 pr-3" v-if="false && inventory.pico_barcode && inventory.barcode_image">
							<div class="mt-2">
								<span class="fw-600">Pico Barcode : </span>
								<span class="fw-500">
									<Barcode circle :src="inventory.barcode_image"></Barcode>
								</span>
							</div>
						</div>
					</div>
				</v-flex>
			</v-layout>
			<div>
				<v-tabs
					v-model="purchaseOrderTab"
					background-color="transparent"
					color="blue"
					class="custom-tab-transparent"
					active-class="blue darken-4 text-white"
					hide-slider
				>
					<v-tab href="#detail"> <v-icon small left>mdi-information-outline</v-icon> Details</v-tab>
					<v-tab href="#line-items">
						<v-icon small left>mdi-format-list-bulleted-square</v-icon> Line Items ({{
							purchase_order.total_items ? purchase_order.total_items : 0
						}})</v-tab
					>
					<v-tab href="#pending" v-if="purchase_order.receive_status != 2">
						<v-icon small left>mdi-format-list-bulleted-square</v-icon> Pending</v-tab
					>
					<v-tab href="#recieved">
						<v-icon small left>mdi-format-list-bulleted-square</v-icon> Received</v-tab
					>
					<v-tab href="#grn"> <v-icon small left>mdi-format-list-bulleted-square</v-icon> GRN</v-tab>
					<v-tab href="#attachment">
						<v-icon small left>mdi-file-document-multiple</v-icon> Attachments</v-tab
					>
					<v-tab href="#event"> <v-icon small left>mdi-history</v-icon> Logs</v-tab>
					<v-tab href="#quantity-by-location" v-if="false">
						<v-icon small left>mdi-map-marker-outline</v-icon> Quantity by Location</v-tab
					>
					<v-tab v-if="false && getPermission('transaction:view')" href="#transaction">
						<v-icon small left>mdi-share-outline</v-icon> Transaction
					</v-tab>
					<v-tab v-if="false" href="#reservation">
						<v-icon small left>mdi-calendar-check</v-icon> Reservations</v-tab
					>
					<v-tab v-if="false" href="#retire_stock_records">
						<v-icon small left>mdi-calendar-check</v-icon> Retire Stock Records</v-tab
					>
					<v-tab href="#related-service" v-if="false">
						<v-icon small left>mdi-hammer-wrench</v-icon> Related Services</v-tab
					>
					<v-tab v-if="false" href="#work-order">
						<v-icon small left>mdi-clipboard-list</v-icon> Work Orders</v-tab
					>
					<v-tab v-if="false" href="#comment">
						<v-icon small left>mdi-comment-multiple</v-icon> Comments</v-tab
					>
					<v-tab v-if="false" href="#support">
						<v-icon small left>mdi-page-next-outline</v-icon> Support</v-tab
					>
				</v-tabs>
				<v-tabs-items v-model="purchaseOrderTab">
					<v-tab-item value="detail">
						<div class="mx-4">
							<div class="overflow-y" style="max-height: calc(100vh - 250px)">
								<v-expansion-panels
									flat
									accordion
									tile
									v-model="expansion_panel"
									multiple
									class="mt-2 transparent-panels overflow-y"
									style="max-height: calc(100vh - 250px)"
								>
									<v-expansion-panel>
										<v-expansion-panel-header>
											<v-layout>
												<v-flex md10>
													<strong>Customer</strong>
													-
													<span class="text-truncate mb-1 bold-500 text-initial">
														{{ purchase_order.customer && purchase_order.customer.display_name }}
													</span>
												</v-flex>
											</v-layout>
										</v-expansion-panel-header>
										<v-expansion-panel-content class="contact-person-list">
											<template>
												<v-layout>
													<v-flex md6 lg3 v-if="purchase_order.customer">
														<div>
															<p
																class="text-truncate mb-1 bold-600 text-initial"
																v-if="purchase_order.customer && purchase_order.customer.display_name"
															>
																<span v-if="false">{{ purchase_order.customer.display_name }}</span>
																<span>Company details</span>
															</p>
														</div>
														<!-- {{ purchase_order.customer }} -->
														<p class="mb-1" v-if="purchase_order.customer.company_name">
															<v-icon left small>mdi-home-city-outline</v-icon>
															<ShowValue
																tooltip
																tooltip-text="company name"
																:object="purchase_order.customer"
																object-key="company_name"
																label="company name"
															></ShowValue>
														</p>
														<p class="mb-1 text-lowercase" v-if="purchase_order.customer.phone">
															<v-icon left small>mdi-phone</v-icon>{{ purchase_order.customer.phone }}
														</p>
														<p class="mb-1 text-lowercase" v-if="purchase_order.customer.email">
															<v-icon left small>mdi-email</v-icon>{{ purchase_order.customer.email }}
														</p>
														<p class="mb-1" v-if="purchase_order.customer.designation">
															<v-icon left small>mdi-briefcase</v-icon> {{ purchase_order.customer.designation }}
														</p>
														<div class="d-flex" v-if="false">
															<div>
																<v-icon left small>mdi-map</v-icon>
															</div>
															<div>
																<p class="mb-1" v-if="purchase_order.customer.unit_number">
																	{{ purchase_order.customer.unit_number }}
																</p>
																<p class="mb-1" v-if="purchase_order.customer.address_line_1">
																	{{ purchase_order.customer.address_line_1 }}
																</p>
																<p class="mb-1" v-if="purchase_order.customer.address_line_2">
																	{{ purchase_order.customer.address_line_2 }}
																</p>
																<p class="mb-1" v-if="purchase_order.customer.address_country">
																	{{ purchase_order.customer.address_country }}
																	{{ purchase_order.customer.address_postal_code }}
																</p>
															</div>
														</div>
													</v-flex>
													<v-flex md6 lg3 v-if="purchase_order.customer_contact_person">
														<div>
															<p class="text-truncate mb-1 bold-600 text-initial">
																<span>Person Incharge</span>
															</p>
														</div>
														<p class="mb-1" v-if="purchase_order.customer_contact_person.display_name">
															<v-icon left small>mdi-account</v-icon>
															{{ purchase_order.customer_contact_person.display_name }}
														</p>
														<p class="mb-1 text-lowercase" v-if="purchase_order.customer_contact_person.phone">
															<v-icon left small>mdi-phone</v-icon
															>{{ purchase_order.customer_contact_person.phone }}
														</p>
														<p class="mb-1 text-lowercase" v-if="purchase_order.customer_contact_person.email">
															<v-icon left small>mdi-email</v-icon
															>{{ purchase_order.customer_contact_person.email }}
														</p>
														<p class="mb-1" v-if="purchase_order.customer_contact_person.designation">
															<v-icon left small>mdi-briefcase</v-icon>
															{{ purchase_order.customer_contact_person.designation }}
														</p>
													</v-flex>
													<v-flex md6 lg3 v-if="purchase_order.customer_default_address">
														<div>
															<p class="text-truncate mb-1 bold-600 text-initial">
																<span>Billing Address</span>
															</p>
														</div>
														<p
															class="mb-1 text-lowercase"
															v-if="purchase_order.customer_default_address.display_name"
														>
															<v-icon left small>mdi-account</v-icon>
															{{ purchase_order.customer_default_address.display_name }}
														</p>
														<p
															class="mb-1 text-lowercase"
															v-if="purchase_order.customer_default_address.phone_number"
														>
															<v-icon left small>mdi-phone</v-icon
															>{{ purchase_order.customer_default_address.phone_number }}
														</p>
														<p
															class="mb-1 text-lowercase"
															v-if="
																purchase_order.customer_default_address &&
																purchase_order.customer_default_address.email
															"
														>
															<v-icon left small>mdi-email</v-icon>
															{{ purchase_order.customer_default_address.email }}
														</p>
														<div class="mb-1 d-flex">
															<div>
																<v-icon left small>mdi-map</v-icon>
															</div>
															<div>
																<p class="mb-1" v-if="purchase_order.customer_default_address.unit_number">
																	{{ purchase_order.customer_default_address.unit_number }}
																</p>
																<p class="mb-1" v-if="purchase_order.customer_default_address.address_line_1">
																	{{ purchase_order.customer_default_address.address_line_1 }}
																</p>
																<p class="mb-1" v-if="purchase_order.customer_default_address.address_line_2">
																	{{ purchase_order.customer_default_address.address_line_2 }}
																</p>
																<p class="mb-1">
																	<template v-if="purchase_order.customer_default_address.address_country">
																		{{ purchase_order.customer_default_address.address_country }}
																	</template>
																	<template v-if="purchase_order.customer_default_address.address_postal_code">
																		{{ purchase_order.customer_default_address.address_postal_code }}
																	</template>
																</p>
															</div>
														</div>
													</v-flex>
													<v-flex md6 lg3 v-if="purchase_order.customer_address">
														<div>
															<p class="text-truncate mb-1 bold-600 text-initial">
																<span>Service Address</span>
															</p>
														</div>
														<p
															class="mb-1 text-lowercase"
															v-if="
																purchase_order.customer_address && purchase_order.customer_address.display_name
															"
														>
															<v-icon left small>mdi-account</v-icon>
															{{ purchase_order.customer_address.display_name }}
														</p>
														<p
															class="mb-1 text-lowercase"
															v-if="purchase_order.customer_address && purchase_order.customer_address.email"
														>
															<v-icon left small>mdi-email</v-icon>
															{{ purchase_order.customer_address.email }}
														</p>
														<p class="mb-1 text-lowercase" v-if="purchase_order.customer_address.phone_no">
															<v-icon left small>mdi-phone</v-icon>{{ purchase_order.customer_address.phone_no }}
														</p>
														<div class="mb-1 d-flex">
															<div>
																<v-icon left small>mdi-map</v-icon>
															</div>
															<div>
																<p class="mb-1" v-if="purchase_order.customer_address.unit_number">
																	{{ purchase_order.customer_address.unit_number }}
																</p>
																<p class="mb-1" v-if="purchase_order.customer_address.address_line_1">
																	{{ purchase_order.customer_address.address_line_1 }}
																</p>
																<p class="mb-1" v-if="purchase_order.customer_address.address_line_2">
																	{{ purchase_order.customer_address.address_line_2 }}
																</p>
																<p class="mb-1">
																	<template v-if="purchase_order.customer_address.address_country">
																		{{ purchase_order.customer_address.address_country }}
																	</template>
																	<template v-if="purchase_order.customer_address.address_postal_code">
																		{{ purchase_order.customer_address.address_postal_code }}
																	</template>
																</p>
															</div>
														</div>
													</v-flex>
												</v-layout>
											</template>
										</v-expansion-panel-content>
									</v-expansion-panel>
									<v-expansion-panel v-if="purchase_order.supplier">
										<v-expansion-panel-header>
											<v-layout>
												<v-flex md10>
													<strong>Supplier</strong>
													-
													<span class="text-truncate mb-1 bold-500 text-initial">
														{{ purchase_order.supplier && purchase_order.supplier.display_name }}
													</span>
												</v-flex>
											</v-layout>
										</v-expansion-panel-header>
										<v-expansion-panel-content class="contact-person-list">
											<template>
												<v-layout>
													<v-flex md6 lg3 v-if="purchase_order.supplier">
														<div>
															<p class="text-truncate mb-1 bold-600 text-initial">
																<span v-if="false">
																	{{ purchase_order.supplier && purchase_order.supplier.display_name }}
																</span>
																<span> Supplier detail </span>
															</p>
														</div>
														<p class="mb-1" v-if="purchase_order.supplier.company_name">
															<v-icon left small>mdi-home-city-outline</v-icon>
															<ShowValue
																tooltip
																tooltip-text="company name"
																:object="purchase_order.supplier"
																object-key="company_name"
																label="company name"
															></ShowValue>
														</p>
														<p
															class="mb-1 text-lowercase"
															v-if="purchase_order.supplier && purchase_order.supplier.phone"
														>
															<v-icon left small>mdi-phone</v-icon>{{ purchase_order.supplier.phone }}
														</p>
														<p
															class="mb-1 text-lowercase"
															v-if="purchase_order.supplier && purchase_order.supplier.email"
														>
															<v-icon left small>mdi-email</v-icon>{{ purchase_order.supplier.email }}
														</p>
													</v-flex>
													<v-flex md6 lg3 v-if="purchase_order.supplier_contact_person">
														<div>
															<p class="text-truncate mb-1 bold-600 text-initial">
																<span>Contact Person</span>
															</p>
														</div>
														<p class="mb-1" v-if="purchase_order.supplier_contact_person.display_name">
															<v-icon left small>mdi-account</v-icon>
															{{ purchase_order.supplier_contact_person.display_name }}
														</p>
														<p class="mb-1 text-lowercase" v-if="purchase_order.supplier_contact_person.phone">
															<v-icon left small>mdi-phone</v-icon
															>{{ purchase_order.supplier_contact_person.phone }}
														</p>
														<p class="mb-1 text-lowercase" v-if="purchase_order.supplier_contact_person.email">
															<v-icon left small>mdi-email</v-icon
															>{{ purchase_order.supplier_contact_person.email }}
														</p>
														<p class="mb-1" v-if="purchase_order.supplier_contact_person.designation">
															<v-icon left small>mdi-briefcase</v-icon>
															{{ purchase_order.supplier_contact_person.designation }}
														</p>
													</v-flex>
													<v-flex md6 lg3 v-if="purchase_order.supplier_address">
														<div>
															<p class="text-truncate mb-1 bold-600 text-initial">
																<span>Address</span>
															</p>
														</div>
														<p class="mb-1 text-lowercase" v-if="purchase_order.supplier_address.display_name">
															<v-icon left small>mdi-account</v-icon>
															{{ purchase_order.supplier_address.display_name }}
														</p>
														<p
															class="mb-1 text-lowercase"
															v-if="purchase_order.supplier_address && purchase_order.supplier_address.email"
														>
															<v-icon left small>mdi-email</v-icon>
															{{ purchase_order.supplier_address.email }}
														</p>
														<p class="mb-1 text-lowercase" v-if="purchase_order.supplier_address.phone_no">
															<v-icon left small>mdi-phone</v-icon>{{ purchase_order.supplier_address.phone_no }}
														</p>
														<div class="mb-1 d-flex">
															<div>
																<v-icon left small>mdi-map</v-icon>
															</div>
															<div>
																<p class="mb-1" v-if="purchase_order.supplier_address.location">
																	{{ purchase_order.supplier_address.location }}
																</p>
																<p class="mb-1" v-if="purchase_order.supplier_address.address_line_1">
																	{{ purchase_order.supplier_address.address_line_1 }}
																</p>
																<p class="mb-1" v-if="purchase_order.supplier_address.address_line_2">
																	{{ purchase_order.supplier_address.address_line_2 }}
																</p>
																<p class="mb-1" v-if="purchase_order.supplier_address.unit_number">
																	{{ purchase_order.supplier_address.unit_number }}
																</p>
																<p class="mb-1">
																	<template v-if="purchase_order.supplier_address.address_country">
																		{{ purchase_order.supplier_address.address_country }}
																	</template>
																	<template v-if="purchase_order.supplier_address.address_postal_code">
																		{{ purchase_order.supplier_address.address_postal_code }}
																	</template>
																</p>
															</div>
														</div>
													</v-flex>
												</v-layout>
											</template>
										</v-expansion-panel-content>
									</v-expansion-panel>
									<v-expansion-panel v-if="purchase_order.project">
										<v-expansion-panel-header>
											<v-layout>
												<v-flex md10>
													<strong>Project</strong>
													-
													<span class="text-truncate mb-1 bold-500 text-initial">
														{{ purchase_order.project && purchase_order.project.name }}
													</span>
													<v-tooltip top>
														<template v-slot:activator="{ on, attrs }">
															<v-icon
																v-on="on"
																v-bind="attrs"
																class="text-right"
																size="16"
																:color="getStatusColor(purchase_order.project.status_value)"
																>mdi-circle</v-icon
															>
														</template>
														<span
															>Project Status -
															<span class="text-uppercase">{{ purchase_order.project.status_value }}</span></span
														>
													</v-tooltip>
												</v-flex>
											</v-layout>
										</v-expansion-panel-header>
										<v-expansion-panel-content class="contact-person-list">
											<template>
												<v-layout>
													<v-flex md10 v-if="purchase_order.project">
														<div v-if="false">
															<p class="text-truncate mb-1 bold-600 text-initial">
																<span>
																	{{ purchase_order.project && purchase_order.project.barcode }} -
																	{{ purchase_order.project && purchase_order.project.name }}
																</span>
																<v-tooltip top>
																	<template v-slot:activator="{ on, attrs }">
																		<v-icon
																			v-on="on"
																			v-bind="attrs"
																			class="text-right"
																			size="16"
																			:color="getStatusColor(purchase_order.project.status_value)"
																			>mdi-circle</v-icon
																		>
																	</template>
																	<span
																		>Project Status -
																		<span class="text-uppercase">{{
																			purchase_order.project.status_value
																		}}</span></span
																	>
																</v-tooltip>
															</p>
														</div>
														<p class="mb-1" v-if="purchase_order.project">
															<b>Project cost : </b>
															<span class="fw-500">
																<ShowPrice
																	:object="purchase_order.project"
																	object-key="cost"
																	label="project cost"
																></ShowPrice>
															</span>
														</p>
														<p
															class="mb-1"
															v-if="purchase_order.project && purchase_order.project.start_date_formatted"
														>
															<b>Start Date : </b>
															{{ purchase_order.project.start_date_formatted }}
														</p>
														<p
															class="mb-1"
															v-if="purchase_order.project && purchase_order.project.end_date_formatted"
														>
															<b>End Date : </b>
															{{ purchase_order.project.end_date_formatted }}
														</p>
														<p class="mb-1" v-if="purchase_order.project">
															<b>Description : </b>
															<ShowTextAreaValue
																:object="purchase_order.project"
																object-key="description"
																label="Description"
															></ShowTextAreaValue>
														</p>
													</v-flex>
												</v-layout>
											</template>
										</v-expansion-panel-content>
									</v-expansion-panel>
									<v-expansion-panel>
										<v-expansion-panel-header>
											<v-layout>
												<v-flex md10><strong>Other Details</strong></v-flex>
											</v-layout>
										</v-expansion-panel-header>
										<v-expansion-panel-content class="contact-person-list">
											<template>
												<table width="100%" class="detail-table scattered-table mt-2">
													<tr v-if="purchase_order.reject_remark">
														<th valign="top" width="300px">Reject Remark</th>
														<td valign="top">
															<ShowTextAreaValue
																:object="purchase_order"
																object-key="reject_remark"
																label="Reject Remark"
															></ShowTextAreaValue>
														</td>
													</tr>
													<tr valign="top">
														<th width="300px">Remark</th>
														<td valign="top">
															<ShowTextAreaValue
																:object="purchase_order"
																object-key="admin_remark"
																label="Remark"
															></ShowTextAreaValue>
														</td>
													</tr>
													<tr valign="top">
														<th width="300px">Terms & Conditions</th>
														<td valign="top">
															<ShowTextAreaValue
																:object="purchase_order"
																object-key="terms_and_conditions_text"
																label="terms and conditions"
															></ShowTextAreaValue>
														</td>
													</tr>
												</table>
											</template>
										</v-expansion-panel-content>
									</v-expansion-panel>
								</v-expansion-panels>
								<div v-if="false">
									<v-row>
										<v-col v-for="(row, index) in addressList" :key="index" md="4" class="py-3">
											<div class="p-4 border-light-grey" style="min-height: 126px">
												<v-layout>
													<v-flex md10 class="d-flex align-center">
														<p class="m-0 font-level-2-bold mr-3">Address #{{ row.barcode }}</p>
														<v-chip
															v-if="row.address_type == 1"
															color="light-blue lighten-4 light-blue--text text--darken-4"
															label
															>Billing Address
														</v-chip>
														<v-chip v-else color="green lighten-4 green--text text--darken-3" label
															>Shipping Address
														</v-chip>
													</v-flex>
													<v-flex md2 class="text-right"> </v-flex>
												</v-layout>
												<v-layout>
													<v-flex md1>
														<v-icon color="blue darken-4">mdi-map-marker-outline</v-icon>
													</v-flex>
													<v-flex md11>
														<p class="m-0">
															<ShowValue :object="row" object-key="name" label="display name"></ShowValue>
														</p>
														<p class="m-0">
															<ShowValue :object="row" object-key="phone_no" label="phone no"></ShowValue>
														</p>
														<p class="m-0">
															<ShowValue :object="row" object-key="email" label="email"></ShowValue>
														</p>
														<p class="m-0">
															<ShowValue :object="row" object-key="address_line_1" label="street 1"></ShowValue>
														</p>
														<p v-if="false" class="m-0">
															<ShowValue :object="row" object-key="address_line_2" label="street 2"></ShowValue>
														</p>
														<!-- <p class="m-0">
															<ShowValue :object="row" object-key="address_city" label="city"></ShowValue>,
														</p> -->
														<p v-if="false" class="m-0">
															<!-- <ShowValue :object="row" object-key="address_state" label="state"></ShowValue>, -->
															<ShowValue
																:object="row"
																object-key="address_postal_code"
																label="postal code"
															></ShowValue>
														</p>
														<p v-if="false">
															<ShowValue :object="row" object-key="address_country" label="country"></ShowValue>
														</p>
														<v-chip
															v-if="row.default"
															color="light-blue lighten-4 light-blue--text text--darken-4"
															label
															>DEFAULT
														</v-chip>
													</v-flex>
												</v-layout>
											</div>
										</v-col>
									</v-row>
								</div>
							</div>
						</div>
					</v-tab-item>
					<v-tab-item value="line-items">
						<LineItemListing
							v-if="purchaseOrderTab == 'line-items' && purchase_order.id"
							ref="po_line_item_linsting_ref"
							:id="purchase_order.id"
							:requestPoData="purchase_order.request_po_data"
							:purcashStaus="purchase_order.status"
							class="mx-4"
							:type-uuid="uuid"
							@success="getPurchaseOrder()"
						></LineItemListing>
					</v-tab-item>
					<v-tab-item value="pending">
						<LineItemListingPending
							v-if="purchaseOrderTab == 'pending' && purchase_order.id"
							:id="purchase_order.id"
							class="mx-4"
							ref="lineItemListingPending"
							:status="purchase_order.status_value"
							:btn-permission="checkPermission()"
							:type-uuid="uuid"
							v-on:update="getUpdatePurchaseOrder()"
							v-on:success="isRecieve = false"
						></LineItemListingPending>
					</v-tab-item>
					<v-tab-item value="recieved">
						<LineItemListingRecieved
							v-if="purchaseOrderTab == 'recieved' && purchase_order.id"
							class="mx-4"
							:id="purchase_order.id"
							:type-uuid="uuid"
						></LineItemListingRecieved>
					</v-tab-item>
					<v-tab-item value="grn">
						<GRNListing
							v-if="purchaseOrderTab == 'grn' && purchase_order.id"
							class="mx-4"
							:type-uuid="uuid"
							:id="purchase_order.id"
						></GRNListing>
					</v-tab-item>
					<v-tab-item value="attachment">
						<Files
							v-if="purchaseOrderTab == 'attachment' && uuid"
							class="mx-4"
							type-text="Purchase Order Files"
							type="purchase-order"
							:type-uuid="uuid"
						></Files>
					</v-tab-item>
					<v-tab-item value="event">
						<EventListing
							v-if="purchaseOrderTab == 'event' && uuid"
							class="mx-4"
							type-text="purchase order"
							type="purchase-order"
							:type-uuid="uuid"
						></EventListing>
					</v-tab-item>
					<v-tab-item value="retire_stock_records" v-if="false">
						<ItemRetirementsListing
							v-if="purchaseOrderTab == 'retire_stock_records'"
							class="mx-4"
							type-text="Inventory"
							type="inventory"
							:uuid="uuid"
						></ItemRetirementsListing>
					</v-tab-item>
					<v-tab-item value="quantity-by-location" v-if="false">
						<QuantityLocation
							v-if="purchaseOrderTab == 'quantity-by-location'"
							class="mx-4"
							type-text="Perishables"
							type="inventory"
							:type-uuid="uuid"
						></QuantityLocation>
					</v-tab-item>
					<v-tab-item value="transaction" v-if="false">
						<template v-if="purchaseOrderTab == 'transaction'">
							<TransactionListing
								relation-type="inventory"
								:relation-type-id="inventory.id"
							></TransactionListing>
						</template>
					</v-tab-item>
					<v-tab-item value="reservation" v-if="false">
						<ReservationListing
							v-if="purchaseOrderTab == 'reservation'"
							class="mx-4"
							type-text="Perishables"
							type="inventory"
							:type-uuid="uuid"
						></ReservationListing>
					</v-tab-item>
					<v-tab-item value="related-service" v-if="false">
						<RelatedServiceTemplate
							v-if="purchaseOrderTab == 'related-service'"
							class="mx-4"
							type-text="Perishables"
							type="inventory"
							:type-uuid="uuid"
						></RelatedServiceTemplate>
					</v-tab-item>
					<v-tab-item value="work-order" v-if="false">
						<WorkOrderListing
							v-if="purchaseOrderTab == 'work-order'"
							class="mx-4"
							type-text="Perishables"
							type="inventory"
							:type-uuid="uuid"
						></WorkOrderListing>
					</v-tab-item>
					<v-tab-item value="comment" v-if="false">
						<Comments
							v-if="purchaseOrderTab == 'comment'"
							class="mx-4"
							type-text="Perishables"
							type="inventory"
							:type-uuid="uuid"
						></Comments>
					</v-tab-item>
					<v-tab-item value="support" v-if="false">
						<SupportTemplate
							v-if="purchaseOrderTab == 'support'"
							class="mx-4"
							type-text="Perishables"
							type="inventory"
							:type-uuid="uuid"
						></SupportTemplate>
					</v-tab-item>
				</v-tabs-items>
			</div>
			<DeleteTemplate
				type="Perishables"
				:delete-text="deleteText"
				v-on:success="backToListing('purchase-order')"
				v-on:close="deleteDialog = false"
				:delete-dialog="deleteDialog"
				:delete-url="deleteURL"
				:delete-note="
					!inventory.retired
						? 'To preserve associated records, you could retire the Perishables instead.'
						: null
				"
			>
				<template v-if="!inventory.retired" v-slot:extra-btn>
					<v-btn
						class="white--text"
						depressed
						color="blue darken-4"
						tile
						v-on:click="
							deleteDialog = false;
							retireDialog = true;
						"
					>
						Retire
					</v-btn>
				</template>
			</DeleteTemplate>
			<ApproveConfirmation
				v-if="approveDialog"
				type="Purchase Order"
				v-on:success="
					approveDialog = false;
					getPurchaseOrder();
				"
				v-on:close="approveDialog = false"
				:approve-dialog="approveDialog"
				status="mark_as_approved"
				:uuid="uuid"
				approve-type="purchase-order"
			>
			</ApproveConfirmation>
			<RejectDialog
				v-if="rejectDialog"
				type="Purchase Order"
				v-on:success="
					rejectDialog = false;
					getPurchaseOrder();
				"
				v-on:close="rejectDialog = false"
				:reject-dialog="rejectDialog"
				status="mark_as_rejected"
				:uuid="uuid"
				reject-type="purchase-order"
			>
			</RejectDialog>
              <RemarkRejectDialog
				v-if="RemarkDialogNew"
				type="Remarks"
				v-on:success="
					RemarkDialogNew = false;
					getPurchaseOrder();
				"
				v-on:close="RemarkDialogNew = false"
				:reject-dialog="RemarkDialogNew"
				:rejctremark="purchase_order.reject_remark"
				:uuid="uuid"
				reject-type="request-order"
			>
			</RemarkRejectDialog>


			<MailTemplateDrawar
				v-if="sendForApproveDialog"
				:dialog="sendForApproveDialog"
				title="Send For Approval"
				type="purchase-order"
				:default-email="purchase_order.supplier.email"
				:type-uuid="uuid"
				v-on:close="sendForApproveDialog = false"
				v-on:success="getPurchaseOrder()"
			></MailTemplateDrawar>
		</template>
		<template v-else>
			<div class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
		</template>
	</v-sheet>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ImageTemplate from "@/view/components/Image";
import Barcode from "@/view/components/Barcode";
import Comments from "@/view/components/Comments";
import Files from "@/view/components/Files";
import QuantityLocation from "@/view/components/QuantityLocation";
import ReservationListing from "@/view/components/ReservationListing";
import TransactionListing from "@/view/components/TransactionListing";
import EventListing from "@/view/components/EventListing";
import WorkOrderListing from "@/view/components/WorkOrderListing";
// import { GetInventory } from "@/core/lib/inventory.lib";
import { GetPurchaseOrder, DownloadPDF, UpdateChangePrice } from "@/core/lib/purchase-order.lib";
import ShowValue from "@/view/components/ShowValue";
import ShowTextAreaValue from "@/view/components/ShowTextAreaValue";
// import { EventBus } from "@/core/event-bus/event.bus";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import ApproveConfirmation from "@/view/components/ApproveConfirmation";
import RemarkRejectDialog from "@/view/components/RejectRemarkPoDialog";
import RejectDialog from "@/view/components/RejectDialog";
import RelatedServiceTemplate from "@/view/components/RelatedServiceTemplate";
import SupportTemplate from "@/view/components/SupportTemplate";
import ItemRetirementsListing from "@/view/components/ItemRetirementsListing";
import LineItemListing from "@/view/components/LineItemListing";
import LineItemListingRecieved from "@/view/components/LineItemListingRecieved";
import GRNListing from "@/view/components/GRNListing";
import LineItemListingPending from "@/view/components/LineItemListingPending";
import MailTemplateDrawar from "@/view/components/MailTemplateDrawar";
import ShowBarcode from "@/view/components/ShowBarcode";
import TooltipQuestion from "@/view/components/TooltipQuestion";
import ShowPrice from "@/view/components/ShowPrice";
import { find } from "lodash";

export default {
	name: "Purchase-order-detail",
	title: "Detail Purchase order",
	data() {
		return {
			// purchaseOrderTab: null,
			isRecieve: false,
			ApproveHidebutton: false,
			RemarkDialogNew: false,
			sendForApproveDialog: false,
			approveDialog: false,
			rejectDialog: false,
			pageLoading: false,
			updatePriceLoading: false,
			uuid: null,
			barcode: null,
			expansion_panel: [0, 1, 2],
			deleteText: null,
			deleteURL: null,
			deleteDialog: false,
			contentLoaded: true,
			inventory: {},
			deviceWidth: document.documentElement.clientWidth,
			product: {},
			purchase_order: {
				barcode: "PO-00000021",
				customer_name: "John",
				project_name: "Test Project",
				supplier_name: "Test Supplier",
				contact_person_name: "Fast tech",
				status: "Draft",
				terms_and_conditions: "",
				remark: "Lorem Ipsum",
				total: 200,
				due_date: "29/12/2022",
				created_at: "29/12/2022",
			},
			addressList: [
				{
					uuid: null,
					barcode: "L-000011",
					contact_person: 0,
					name: "sdfsd",
					company_name: null,
					phone_no: "+65-341235",
					email: "sfdgupdated@sdfgas.dgh",
					id_number: null,
					latitude: null,
					longitude: null,
					address_type: 2,
					address_line_1: "asfa",
					address_line_2: null,
					address_country: null,
					address_state: null,
					address_city: null,
					address_postal_code: null,
					status_value: "active",
					default: 0,
					added_by: 1,
					added_at: "28/12/2022 12:21 PM",
					updated_by: 0,
					updated_at: null,
					created_by: "Super Admin",
					modified_by: null,
					created_at: "1 day ago",
					modified_at: null,
				},
				{
					uuid: null,
					barcode: "L-000011",
					contact_person: 0,
					name: "sdfsd",
					company_name: null,
					phone_no: "+65-341235",
					email: "sfdgupdated@sdfgas.dgh",
					id_number: null,
					latitude: null,
					longitude: null,
					address_type: 1,
					address_line_1: "asfa",
					address_line_2: null,
					address_country: null,
					address_state: null,
					address_city: null,
					address_postal_code: null,
					status_value: "active",
					default: 0,
					added_by: 1,
					added_at: "28/12/2022 12:21 PM",
					updated_by: 0,
					updated_at: null,
					created_by: "Super Admin",
					modified_by: null,
					created_at: "1 day ago",
					modified_at: null,
				},
			],
		};
	},
	created() {
		window.addEventListener("resize", this.resizeEventHandler);
	},
	destroyed() {
		window.removeEventListener("resize", this.resizeEventHandler);
	},
	computed: {
		purchaseOrderTab: {
			set(val) {
				let query = { ...this.$route.query };
				query.tab = val;
				if (val != this.purchaseOrderTab) {
					this.$router.replace({ query });
				}
			},
			get() {
				return this.$route.query.tab || "purchase-order";
			},
		},
		checkPermission: () => {
			return function () {
				if (this.purchase_order.receive_status != 2 && this.purchase_order.force_close_status != 1) {
					return true;
				}
				return false;
			};
		},
	},
	methods: {
		getRemarkDialog() {
			this.RemarkDialogNew = true;
		},
		checkApproveHidebuttonButton() {
			this.ApproveHidebutton = false;
			if (this.purchase_order && this.purchase_order.line_items) {
                 if(!this.purchase_order.request_po){
					this.ApproveHidebutton = true;
					return;
				 }
				let ApproveHideData = find(
					this.purchase_order.line_items,
					(row) => row.original_product.status == 3 || row.is_approve == 0
				);

				if (!ApproveHideData) {
					this.ApproveHidebutton = true;
				}
			}
		},

		RequestPoDetail(uuid) {
			this.$router.replace({
				name: "request-po-detail",
				params: { uuid },
				query: { t: new Date().getTime() },
			});
		},

		resizeEventHandler() {
			this.deviceWidth = document.documentElement.clientWidth;
		},
		doAction(param) {
			switch (param) {
				case "edit":
					this.$router.push({
						name: "purchase-order-update",
						params: { uuid: this.uuid },
						query: { t: new Date().getTime() },
					});
					break;
				case "send-for-approval":
					this.sendForApproveDialog = true;
					break;
				case "approve":
					this.$router.push({
						name: "purchase-order-update",
						params: { uuid: this.uuid },
						query: {
							t: new Date().getTime(),
							status: "approve",
						},
					});

					//this.approveDialog = true;
					break;
				case "reject":
					this.rejectDialog = true;
					break;
				case "reject-sample":
					this.$router.push({
						name: "purchase-order-update",
						params: { uuid: this.uuid },
						query: { t: new Date().getTime() },
					});
					break;
			}
		},
		downloadPDF() {
			DownloadPDF({ uuid: this.uuid, url: "generate-pdf/purchase-order/" });
		},
		async updateChangePrice() {
			this.updatePriceLoading = true;
			try {
				await UpdateChangePrice(this.uuid);
				this.getPurchaseOrder();
				if (this.purchaseOrderTab == "line-items") {
					this.$refs.po_line_item_linsting_ref.getPoLineItems(this.purchase_order.id);
				}
			} catch (error) {
				console.log(error);
			} finally {
				this.updatePriceLoading = false;
			}

			// EventBus.$emit("reload:line-item",true);
		},
		getUpdatePurchaseOrder() {
			this.purchaseOrderTab = "recieved";
			this.getPurchaseOrder();
		},
		getPurchaseOrder() {
			const _this = this;
			GetPurchaseOrder(_this.uuid)
				.then((data) => {
					_this.barcode = data.barcode;
					// _this.inventory = data;
					// _this.deleteText = `#${data.barcode} - ${data.name}`;
					// _this.deleteURL = `inventory/${data.uuid}`;

					_this.purchase_order = data;

					_this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Purchase Order", disabled: true },
						{ text: "Detail", disabled: true },
						{ text: _this.barcode, disabled: true },
					]);
					this.checkApproveHidebuttonButton();
				})
				.catch((error) => {
					console.log(error, "asdfa");
					_this.backToListing("purchase-order");
					_this.logError(error);
				})
				.finally(() => {
					_this.pageLoading = false;
					_this.contentLoaded = true;
				});
		},
		recieveItem() {
			this.isRecieve = true;
			this.purchaseOrderTab = "pending";
			let _this = this;
			setTimeout(() => {
				if (_this.$refs.lineItemListingPending) {
					_this.$nextTick(() => {
						_this.$refs.lineItemListingPending.recieveItem();
					});
				}
			}, 300);
		},
		cancleRecieveItem() {
			this.isRecieve = false;
			this.purchaseOrderTab = "pending";
			this.$nextTick(() => {
				if (this.$refs.lineItemListingPending) {
					this.$refs.lineItemListingPending.isRecieve = false;
				}
			});
		},
		partiallyRecieved() {
			this.purchaseOrderTab = "pending";
			this.$nextTick(() => {
				if (this.$refs.lineItemListingPending) {
					this.$refs.lineItemListingPending.partiallyRecieved();
				}
			});
		},
		fullyRecieved() {
			this.purchaseOrderTab = "pending";
			this.$nextTick(() => {
				if (this.$refs.lineItemListingPending) {
					this.$refs.lineItemListingPending.fullyRecieved();
				}
			});
		},
		forceClose() {
			this.purchaseOrderTab = "pending";
			this.$nextTick(() => {
				if (this.$refs.lineItemListingPending) {
					this.$refs.lineItemListingPending.forceClose();
				}
			});
		},
	},
	components: {
		Files,
		Comments,
		QuantityLocation,
		ReservationListing,
		TransactionListing,
		EventListing,
		WorkOrderListing,
		ImageTemplate,
		ShowValue,
		ShowTextAreaValue,
		ItemRetirementsListing,
		DeleteTemplate,
		ApproveConfirmation,
		RejectDialog,
		RelatedServiceTemplate,
		SupportTemplate,
		Barcode,
		LineItemListing,
		LineItemListingRecieved,
		GRNListing,
		LineItemListingPending,
		MailTemplateDrawar,
		ShowBarcode,
		TooltipQuestion,
		ShowPrice,
		RemarkRejectDialog,
	},
	beforeMount() {
		const { uuid } = this.$route.params;
		this.uuid = uuid;
		if (!this.uuid) {
			this.backToListing("purchase-order");
		}
		this.getPurchaseOrder();
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Purchase Order", disabled: true },
			{ text: "Detail", disabled: true },
		]);
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
	watch:{
		purchaseOrderTab(param){
			if(param != 'pending' && this.isRecieve){
				this.isRecieve = false;
			}
		}
	}
	
};
</script>
